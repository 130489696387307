import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import illustration from '../../../resources/lottie-animations/globalization.json';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnimatedIllustration = makeShortcode("AnimatedIllustration");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimatedIllustration data={illustration} css={{
      marginBottom: 32
    }} mdxType="AnimatedIllustration" />
    <h1 {...{
      "id": "global-design"
    }}>{`Global Design`}</h1>
    <h2 {...{
      "id": "philosophy"
    }}>{`Philosophy`}</h2>
    <p>{`Workday aims to ensure that users can comfortably and intuitively use our products regardless of
cultural background, language, or location; and that we not only adhere to global best practices,
but create innovative ways to improve global functionality and create inclusive experiences.`}</p>
    <h2 {...{
      "id": "approach"
    }}>{`Approach`}</h2>
    <p>{`Workday’s approach to Globalization recognizes that the needs of all our users are not the same and
that there are variations by region, language, national culture, local culture, industry and size of
organization. Elements and functionality which are seemingly inconsequential to a U.S.-based user
may be fundamental to users from other cultures.`}</p>
    <p>{`We follow global best practices to meet regional customer needs. The technical practices we leverage
include the:`}</p>
    <ul>
      <li parentName="ul">{`International Organization for Standardization (ISO), which standardizes products and processes,
including those for software development.`}</li>
      <li parentName="ul">{`International Components for Unicode for Java (ICU4J), which provides a set of libraries that help
software perform on all platforms.`}</li>
      <li parentName="ul">{`Common Locale Data Repository (CLDR), which provides data to help software support different
languages and locales.`}</li>
    </ul>
    <p>{`We partner with our global customers to understand their pain points, needs, and motivations and
solicit feedback on our designs. We aim to conduct foundational research and usability testing with
users of varied cultural backgrounds and in different regions in order to ensure that our products
and experiences meet a wide variety of needs.`}</p>
    <h2 {...{
      "id": "understanding-global-users"
    }}>{`Understanding Global Users`}</h2>
    <p>{`Technology is neither neutral nor static; it is used differently by different communities and it is
shaped by the culture of those who create it. To have an effective global approach, we cannot design
from just one perspective. We incorporate tools and processes to be as inclusive as possible in how
we develop product and design experiences.`}</p>
    <h3 {...{
      "id": "research"
    }}>{`Research`}</h3>
    <p>{`At Workday, we continuously examine what cultural biases may make their way into the technology, and
we consider the needs of a broad range of communities when designing technology. Based on our
experiences conducting user research to inform a globally relevant product, we have a few
recommendations:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Consider cultural, linguistic, and national differences early on in the product roadmap.`}</p>
        <p parentName="li">{`Cross-cultural research should influence all product roadmaps`}{`—`}{`it is a must-have. Trying to
accommodate the needs of global customers later on or retrofitting your product to other markets
and communities is much harder and costlier than considering these factors to start off with.
Build cross-cultural research into the product and design planning process wherever possible.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Leverage cross-cultural research to tap into previously unknown areas for innovation.`}</p>
        <p parentName="li">{`Research can reveal untapped opportunities and unmet needs that are especially pronounced in one
context and not yet fully understood in another. For example, when considering the needs of
cultures which are less oriented toward an individualist mindset and are more group-oriented, we
might discover opportunities for helping all users succeed as teams.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Be aware that cross-cultural user research is complex and requires careful planning to be
successful.`}</p>
        <p parentName="li">{`Try to find partners within your organization or outside it who have experience working
cross-culturally.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "content-and-visual-considerations"
    }}>{`Content and Visual Considerations`}</h3>
    <p>{`Users around the globe will have different interpretations of the information in the product, how
that information is represented, and how they can interact with the product. When designing for a
global product, keep in mind that users have different cultural frames of reference which can
influence the following:`}</p>
    <ul>
      <li parentName="ul">{`Perception of icons, their symbolism, and their meaning`}</li>
      <li parentName="ul">{`Perception of color and the connotations specific to different colors`}</li>
      <li parentName="ul">{`Preference for and comfort with the quantity of information on the page`}</li>
      <li parentName="ul">{`Effectiveness of message hierarchy`}{`—`}{`readers of other languages are used to different text
structures (such as headings, subheadings, styling of text, and more) which may influence how they
skim and scan, and also how language is organized`}</li>
      <li parentName="ul">{`Perception of voice and tone`}{`—`}{`words need to be chosen purposefully with a global audience in
mind. For more on language, see the Translations article.`}</li>
    </ul>
    <p>{`When conducting research, listen for the language participants use in order to inform choices in
terminology that will appear in the product. The words participants use can provide insight into
their mental model and what terms will make sense to them.`}</p>
    <h3 {...{
      "id": "interaction-considerations"
    }}>{`Interaction Considerations`}</h3>
    <ul>
      <li parentName="ul">{`Input methods: Every language may have a different keyboard setup and input methods across varying
devices.`}</li>
      <li parentName="ul">{`Demographics: Understanding the demographics of users may determine how they interact with the
software service.`}</li>
      <li parentName="ul">{`Field Placement and Order: Different cultures and languages require different fields and in
specific orders. For example:`}
        <ul parentName="li">
          <li parentName="ul">{`In Japanese, the postcode should appear before all other address fields.`}</li>
          <li parentName="ul">{`In Spanish, names need to display the Paternal Surname first, followed by the Maternal Surname.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "impact"
    }}>{`Impact`}</h2>
    <p>{`We translate the product into over 30 languages and offer over 80 different locales. Further, the
combination of localization and translation produces over 2,000 unique user experiences. For
example, a user may be a German expatriate, working in New York for an Israeli company, so she may
choose to have her interface display localized to the U.S. in the Hebrew language.`}</p>
    <p>{`Users have several controls related to how things will be formatted and displayed within Workday:`}</p>
    <ul>
      <li parentName="ul">{`Language: Controls the language itself`}</li>
      <li parentName="ul">{`Locale: Controls the locale setting, like date and currency formats`}</li>
      <li parentName="ul">{`Preferred Currency: Displays the users preferred currency`}</li>
    </ul>
    <p>{`With over 20 million users in over 160 countries and with over half those users coming from outside
of the U.S., Workday aims to deliver a culturally and linguistically inclusive user experience to
our customers.`}</p>
    <h2 {...{
      "id": "implementation"
    }}>{`Implementation`}</h2>
    <p>{`As Workday designs and acquires new technologies, we need to consider how to ensure these can be
translated and made accessible for the global market.`}</p>
    <p>{`We have several checklists that teams can use to ensure products are global-ready:`}</p>
    <ul>
      <li parentName="ul">{`i18n, Internationalization: The adapting of software to meet regional and cultural standards for
technical and functional requirements`}</li>
      <li parentName="ul">{`l10n, Localization: Translation and support of the software application and documentation in
various languages`}</li>
      <li parentName="ul">{`g11n, Globalization: Encompasses both Localization and Internationalization. Globalization is the
process of designing, developing, and testing applications with extensibility, allowing for
flexibility in functionality and content to serve varied global markets. Several examples of areas
the globalization process encompasses are: local or regional HR hiring processes, payroll
integration systems, and mailing address requirements and validations.`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "questions-reach-out"
      }}>{`Questions? Reach Out.`}</h2>
      <p>{`For questions about Internationalization or localization, contact
`}<a parentName="p" {...{
          "href": "//workday-dev.slack.com/messages/CUJN3HC2Z"
        }}>{`#ask-globalization`}</a>{`, or Manabu Okuhara.`}</p>
      <p>{`For questions about translations, contact
`}<a parentName="p" {...{
          "href": "//workday-dev.slack.com/messages/C3ZLATQM9"
        }}>{`#ask-translations`}</a>{`.`}</p>
      <p>{`To discuss any topic related to research and design for the global market, contact the Product Equity + Inclusion team at at `}<a parentName="p" {...{
          "href": "https://workday.enterprise.slack.com/archives/C01SLQWB7S4"
        }}>{`#product-equity-inclusion`}</a>{`.`}</p>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      